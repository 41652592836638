import { defineComponent } from 'vue';
import { clientStateStore } from '@/state/clientStateStore';
import { authStore } from '@/state/authStore';
export default defineComponent({
  props: {
    patid: {
      type: String,
      required: true
    },
    dialog: {
      type: String,
      "default": ''
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    showRoseHelperRocket: function showRoseHelperRocket() {
      return clientStateStore.state.roseHelperActive;
    },
    pvskey: function pvskey() {
      return authStore.getters.pvskey;
    }
  },
  mounted: function mounted() {},
  methods: {
    onIconClicked: function onIconClicked() {
      this.$emit('open-patient');
    }
  }
});